import { useContext } from 'react';

import { sortSystemsByName, sortConsumersByName } from 'utils/systems';
import {
  getSystemsRequest,
  getConsumersRequest,
  createSystemRequest,
  getAllSystemsRequest,
  editSystemRequest,
  deleteSystemRequest,
} from 'utils/request/systems';

import { SystemsContext } from './index';
import * as actions from './actions';

let dispatcher;

async function getSystems() {
  try {
    dispatcher(actions.getSystems.request());

    const { items } = await getSystemsRequest();

    dispatcher(actions.getSystems.success(sortSystemsByName(items)));
  } catch (error) {
    dispatcher(actions.getSystems.failure(error));
  }
}

function resetGetSystems() {
  dispatcher(actions.getSystems.reset());
}

async function getAllSystems() {
  try {
    dispatcher(actions.getAllSystems.request());

    const { items } = await getAllSystemsRequest();

    dispatcher(actions.getAllSystems.success(sortSystemsByName(items)));
  } catch (error) {
    dispatcher(actions.getAllSystems.failure(error));
  }
}

function resetGetAllSystems() {
  dispatcher(actions.getAllSystems.reset());
}

async function getConsumers() {
  try {
    dispatcher(actions.getConsumers.request());

    const { items } = await getConsumersRequest();

    dispatcher(actions.getConsumers.success(sortConsumersByName(items)));
  } catch (error) {
    dispatcher(actions.getConsumers.failure(error));
  }
}

function resetGetConsumers() {
  dispatcher(actions.getConsumers.reset());
}

async function createSystem(payload) {
  try {
    dispatcher(actions.createSystem.request());

    await createSystemRequest(payload);

    dispatcher(actions.createSystem.success());
  } catch (error) {
    dispatcher(actions.createSystem.failure(error));
  }
}

function resetCreateSystem() {
  dispatcher(actions.createSystem.reset());
}

async function editSystem(payload) {
  try {
    dispatcher(actions.editSystem.request());

    await editSystemRequest(payload);

    dispatcher(actions.editSystem.success());
  } catch (error) {
    dispatcher(actions.editSystem.failure(error));
  }
}

function resetEditSystem() {
  dispatcher(actions.editSystem.reset());
}

async function deleteSystem(payload) {
  try {
    dispatcher(actions.deleteSystem.request());

    await deleteSystemRequest(payload);

    dispatcher(actions.deleteSystem.success());
  } catch (error) {
    dispatcher(actions.deleteSystem.failure(error));
  }
}

function resetDeleteSystem() {
  dispatcher(actions.deleteSystem.reset());
}

export function useDispatcherSystems() {
  const { dispatch } = useContext(SystemsContext);
  dispatcher = dispatch;

  return {
    getSystems,
    resetGetSystems,
    getConsumers,
    resetGetConsumers,
    createSystem,
    resetCreateSystem,
    getAllSystems,
    resetGetAllSystems,
    editSystem,
    resetEditSystem,
    deleteSystem,
    resetDeleteSystem,
  };
}

export const instanceSizeOptions = [
  {
    value: 'SMALL',
    label: 'Small',
  },
  {
    value: 'MEDIUM',
    label: 'Medium',
  },
  {
    value: 'LARGE',
    label: 'Large',
  },
];

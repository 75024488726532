import { useContext } from 'react';
import {
  getVerificationMessagesRequest,
  createVerificationMessagesRequest,
  deleteVerificationMessagesRequest,
  getDetailVerificationMessagesRequest,
} from 'utils/request/verifyMessages';
import { VerifyMessagesContext } from './index';
import * as actions from './actions';
import moment from 'moment-timezone';
import camelcaseKeys from 'camelcase-keys';

let dispatcher;

async function getVerificationMessagesHistory() {
  try {
    dispatcher(actions.getVerificationMessagesHistory.request());
    let { items } = await getVerificationMessagesRequest();
    dispatcher(actions.getVerificationMessagesHistory.success(items));
  } catch (error) {
    dispatcher(actions.getVerificationMessagesHistory.failure(error));
  }
}

async function getDetailVerificationMessages(verificationId) {
  try {
    dispatcher(actions.getDetailVerificationMessages.request());
    let { items } = await getDetailVerificationMessagesRequest(verificationId);
    let detailVerification = items[0];
    const partialFoundMessages = [...detailVerification.partial_found_messages];
    detailVerification = camelcaseKeys(detailVerification, { deep: true });
    detailVerification.partialFoundMessages = partialFoundMessages;
    dispatcher(actions.getDetailVerificationMessages.success(detailVerification));
  } catch (error) {
    dispatcher(actions.getDetailVerificationMessages.failure(error));
  }
}

async function createVerificationMessages(payload) {
  try {
    const { startDate, endDate, isFilterSet, propertiesFilter, system, topic, valuesFilter, instanceSize } = payload;
    const startDateUTC = moment(startDate).utc().format();
    const endDateUTC = moment(endDate).utc().format();
    const dataRequest = {
      topic: topic,
      system: system,
      start_date: startDateUTC,
      end_date: endDateUTC,
      is_filter_set: isFilterSet,
      instance_type: instanceSize,
      body_properties: [],
    };

    if (valuesFilter && valuesFilter?.headers) {
      dataRequest.header_properties = {
        ...valuesFilter?.headers,
      };
    }

    if (propertiesFilter) {
      dataRequest.body_properties = [...propertiesFilter];
    }
    dispatcher(actions.createVerificationMessages.request());
    await createVerificationMessagesRequest(dataRequest);
    dispatcher(actions.createVerificationMessages.success());
  } catch (error) {
    dispatcher(actions.createVerificationMessages.failure(error));
  }
}

async function deleteVerificationMessages(payload) {
  try {
    dispatcher(actions.deleteVerificationMessages.request());
    await deleteVerificationMessagesRequest(payload);
    dispatcher(actions.deleteVerificationMessages.success());
  } catch (error) {
    dispatcher(actions.deleteVerificationMessages.failure(error));
  }
}

function resetGetVerificationMessagesHistory() {
  dispatcher(actions.getVerificationMessagesHistory.reset());
}

function resetCreateVerificationMessages() {
  dispatcher(actions.createVerificationMessages.reset());
}

function resetDeleteVerificationMessages() {
  dispatcher(actions.deleteVerificationMessages.reset());
}

function resetGetDetailVerificationMessages() {
  dispatcher(actions.getDetailVerificationMessages.reset());
}

export function useDispatcherVerificationMessages() {
  const { dispatch } = useContext(VerifyMessagesContext);

  dispatcher = dispatch;

  return {
    getVerificationMessagesHistory,
    getDetailVerificationMessages,
    createVerificationMessages,
    deleteVerificationMessages,
    resetGetVerificationMessagesHistory,
    resetGetDetailVerificationMessages,
    resetCreateVerificationMessages,
    resetDeleteVerificationMessages,
  };
}

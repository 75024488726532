export default {
  RECORDS: '/',
  INDIVIDUAL_INSTANCES: '/individual-instances',
  GENERAL_INSTANCES: '/general-instances',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  ACCESS_MANAGEMENT: '/access-management',
  CATALOGS_MANAGEMENT: '/catalogs-management',
  VERIFY_MESSAGES: '/verify-messages',
};

import produce from 'immer';

import { getIndividualInstances, editIndividualInstance, checkHealth } from './actions';

export const initialState = {
  individualInstances: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  editIndividualInstance: {
    loading: false,
    loaded: false,
    error: null,
  },

  checkHealth: {
    loading: false,
    loaded: false,
    error: null,
    status: {},
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getIndividualInstances.REQUEST:
        draft.individualInstances.loading = true;
        draft.individualInstances.loaded = false;
        draft.individualInstances.error = null;
        break;

      case getIndividualInstances.SUCCESS:
        draft.individualInstances.loading = false;
        draft.individualInstances.loaded = true;
        draft.individualInstances.error = null;
        draft.individualInstances.list = payload;
        break;

      case getIndividualInstances.FAILURE:
        draft.individualInstances.loading = false;
        draft.individualInstances.loaded = false;
        draft.individualInstances.error = payload;
        break;

      case getIndividualInstances.RESET:
        draft.individualInstances = { ...initialState.individualInstances };
        break;

      case editIndividualInstance.REQUEST:
        draft.editIndividualInstance.loading = true;
        draft.editIndividualInstance.loaded = false;
        draft.editIndividualInstance.error = null;
        break;

      case editIndividualInstance.SUCCESS: {
        draft.editIndividualInstance.loading = false;
        draft.editIndividualInstance.loaded = true;
        draft.editIndividualInstance.error = null;

        draft.individualInstances.list = draft.individualInstances.list.map(item =>
          item.topic === payload.topic ? { ...payload } : item
        );
        break;
      }

      case editIndividualInstance.FAILURE:
        draft.editIndividualInstance.loading = false;
        draft.editIndividualInstance.loaded = false;
        draft.editIndividualInstance.error = payload;
        break;

      case editIndividualInstance.RESET:
        draft.editIndividualInstance = { ...initialState.editIndividualInstance };
        break;

      case checkHealth.REQUEST:
        draft.checkHealth.loading = true;
        draft.checkHealth.loaded = false;
        draft.checkHealth.error = null;
        draft.checkHealth.status = '';
        break;

      case checkHealth.SUCCESS:
        draft.checkHealth.loading = false;
        draft.checkHealth.loaded = true;
        draft.checkHealth.error = null;
        draft.checkHealth.status = payload?.status;
        break;

      case checkHealth.FAILURE:
        draft.checkHealth.loading = false;
        draft.checkHealth.loaded = false;
        draft.checkHealth.error = payload;
        draft.checkHealth.status = '';
        break;

      case checkHealth.RESET:
        draft.checkHealth = { ...initialState.checkHealth };
        break;

      default:
        return draft;
    }
  });

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';

import FeatureProvider from 'providers/FeatureProvider';
import AccessManagementRole from 'providers/AccessManagementRole';
import AccessManagementSection from 'providers/AccessManagementSection';
import AccessManagementPermission from 'providers/AccessManagementPermission';
import AccessManagementUserRole from 'providers/AccessManagementUserRole';
import Instances from 'providers/Instances';
import IndividualInstances from 'providers/IndividualInstances';
import Records from 'providers/Records';
import Topics from 'providers/Topics';
import Systems from 'providers/Systems';
import Compose from 'providers/ProviderComposer';
import VerificationMessages from './VerificationMessages';

function Providers({ children, features }) {
  return (
    <FeatureProvider features={features}>
      <Compose
        components={[
          AccessManagementPermission,
          AccessManagementRole,
          AccessManagementSection,
          AccessManagementUserRole,
          Instances,
          IndividualInstances,
          Records,
          Topics,
          Systems,
          VerificationMessages,
        ]}>
        {React.Children.only(children)}
      </Compose>
    </FeatureProvider>
  );
}
Providers.propTypes = {
  children: PropTypes.node,
  features: PropTypes.array,
};
export default Providers;

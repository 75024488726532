import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FaTachometerAlt, FaServer, FaShieldAlt, FaDiceD6, FaBook, FaCheckCircle } from 'react-icons/fa';
import clsx from 'clsx';

import { useFeature } from 'providers/FeatureProvider';
import Navbar from 'components/Navbar';
import SideNav from 'components/SideNav';

import ROUTES from 'utils/routes';
import './styles.scss';

export function Layout({ children }) {
  const [small, setSmall] = useState(false);

  return (
    <>
      <Navbar title="MUNUS" onSmall={() => setSmall(!small)} />
      <div className="app-container">
        <SideNav
          key="sideNav"
          small={small}
          content={[
            {
              icon: <FaTachometerAlt />,
              to: ROUTES.RECORDS,
              label: 'Records',
              hasPermission: useFeature('recordsRead'),
            },
            {
              icon: <FaDiceD6 />,
              to: ROUTES.INDIVIDUAL_INSTANCES,
              label: 'Individual instances',
              hasPermission: useFeature('individualInstancesRead'),
            },
            {
              icon: <FaServer />,
              to: ROUTES.GENERAL_INSTANCES,
              label: 'General instances',
              hasPermission: useFeature('generalInstancesRead'),
            },
            {
              icon: <FaCheckCircle />,
              to: ROUTES.VERIFY_MESSAGES,
              label: 'Verify Messages',
              hasPermission: useFeature('verificationMessagesRead'),
            },
            {
              icon: <FaBook />,
              to: ROUTES.CATALOGS_MANAGEMENT,
              label: 'Catalogs management',
              hasPermission: useFeature('systemsRead'),
            },
            {
              icon: <FaShieldAlt />,
              to: ROUTES.ACCESS_MANAGEMENT,
              label: 'Access management',
              hasPermission: useFeature('accessManagementVisualizeSection'),
            },
          ]}
        />

        <div className={clsx('contentPage', { contentPageSmall: small })}>
          <main className="main-container">{children}</main>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;

import { useContext } from 'react';

import { sortTopicsByName } from 'utils/topics';
import { getTopicsRequest } from 'utils/request/topics';

import { TopicsContext } from './index';
import * as actions from './actions';

let dispatcher;

async function getTopics() {
  try {
    dispatcher(actions.getTopics.request());

    const { items } = await getTopicsRequest();

    dispatcher(actions.getTopics.success(sortTopicsByName(items)));
  } catch (error) {
    dispatcher(actions.getTopics.failure(error));
  }
}

function resetGetTopics() {
  dispatcher(actions.getTopics.reset());
}

export function useDispatcherTopics() {
  const { dispatch } = useContext(TopicsContext);
  dispatcher = dispatch;

  return { getTopics, resetGetTopics };
}

/* eslint-disable no-useless-escape */
export const onlyLettersRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ_]+$/;
export const onlyAlphanumericRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9]+$/;
export const propertyObjectRegex = /^\w+$/;
export const versionRegex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
export const nameRegex = /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _]*$/;
export const nameRegexWithDot = /^[ñÑA-Za-z _]*[ñÑA-Za-z][ñÑA-Za-z _ .]*$/;
export const UUIDRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const urlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

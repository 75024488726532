import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import camelCase from 'lodash/camelCase';

import { Label, Icon, Dropdown, DropdownItem } from '@gbm/queen-ui-guidelines';
import styles from './styles.module.scss';

const DropdownController = forwardRef(function DropdownController(props, ref) {
  const { options, onChange, label, required, placeholder, disabled, id, className, size } = props;
  return (
    <div className={styles.selectField}>
      {label && <Label required={required}>{label}</Label>}

      <Dropdown
        data-testid={id}
        id={id}
        disabled={disabled}
        color="light"
        label={placeholder}
        iconButton={<Icon className="mdi mdi-chevron-down" />}
        className={clsx(styles.dropdown, className)}
        variant="button"
        size={size}
        ref={ref}>
        {options?.map(item => {
          const id = item.value || item.name || item.id || item.code;
          return (
            <DropdownItem data-testid={camelCase(id)} id={camelCase(id)} onClick={() => onChange(item)} key={id}>
              {item.label || item.displayName || item.name}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </div>
  );
});

DropdownController.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  ref: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
};

DropdownController.muiName = 'DropdownController';

export default DropdownController;

import produce from 'immer';

import { getInstances, createInstance, deleteInstance } from './actions';

export const initialState = {
  instances: {
    loading: false,
    loaded: false,
    error: null,
    data: [],
  },
  instanceCreation: {
    loading: false,
    loaded: false,
    error: null,
  },
  instanceDeletion: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getInstances.REQUEST:
        draft.instances.loading = true;
        draft.instances.loaded = false;
        draft.instances.error = null;
        break;

      case getInstances.SUCCESS:
        draft.instances.loading = false;
        draft.instances.loaded = true;
        draft.instances.data = payload;
        break;

      case getInstances.FAILURE:
        draft.instances.loading = false;
        draft.instances.loaded = false;
        draft.instances.error = payload;
        break;

      case getInstances.RESET:
        draft.instances = { ...initialState.instances };
        break;

      case createInstance.REQUEST:
        draft.instanceCreation.loading = true;
        draft.instanceCreation.loaded = false;
        draft.instanceCreation.error = null;
        break;

      case createInstance.SUCCESS:
        draft.instanceCreation.loading = false;
        draft.instanceCreation.loaded = true;
        break;

      case createInstance.FAILURE:
        draft.instanceCreation.loading = false;
        draft.instanceCreation.loaded = false;
        draft.instanceCreation.error = payload;
        break;

      case createInstance.RESET:
        draft.instanceCreation = { ...initialState.instanceCreation };
        break;

      case deleteInstance.REQUEST:
        draft.instanceDeletion.loading = true;
        draft.instanceDeletion.loaded = false;
        draft.instanceDeletion.error = null;
        break;

      case deleteInstance.SUCCESS:
        draft.instanceDeletion.loading = false;
        draft.instanceDeletion.loaded = true;
        break;

      case deleteInstance.FAILURE:
        draft.instanceDeletion.loading = false;
        draft.instanceDeletion.loaded = false;
        draft.instanceDeletion.error = payload;
        break;

      case deleteInstance.RESET:
        draft.instanceDeletion = { ...initialState.instanceDeletion };
        break;

      default:
        return draft;
    }
  });

export default reducer;

import React from 'react';
import { FormGroup, Label, Icon, Button, Input, Margin, Grid, Typography } from '@gbm/queen-ui-guidelines';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const ManageValuesToVerify = ({
  checkIfPropertyIsValid,
  checkIfValueIsValid,
  setProperty,
  property,
  setValue,
  value,
  values,
  addValue,
  removeValue,
}) => {
  return (
    <>
      <div className="d-flex flex-direction-row justify-content-start align-items-end my-3">
        <Margin side="right" xs={24}>
          <Grid row>
            <Grid sm={6}>
              <FormGroup>
                <Label htmlFor="inputProperty" required>
                  Add property
                </Label>
                <Input
                  disabled={false}
                  error={checkIfPropertyIsValid(property).error}
                  errorMsg={checkIfPropertyIsValid(property).error && checkIfPropertyIsValid(property).message}
                  placeholder="Property"
                  id="inputProperty"
                  data-testid="inputProperty"
                  required={false}
                  onChange={event => setProperty(event.target.value)}
                  value={property}
                />
              </FormGroup>
            </Grid>
            <Grid sm={6}>
              <FormGroup>
                <Label htmlFor="inputProperty" required>
                  Add value
                </Label>
                <Input
                  disabled={false}
                  error={checkIfValueIsValid(value).error}
                  errorMsg={checkIfValueIsValid(value).error && checkIfValueIsValid(value).message}
                  placeholder="Value"
                  id="inputValue"
                  data-testid="inputValue"
                  required={false}
                  onChange={event => setValue(event.target.value)}
                  value={value}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Margin>
        <Button
          className={styles.buttonAddProperty}
          size="sm"
          onClick={() => addValue()}
          disabled={!property || checkIfPropertyIsValid().error || checkIfValueIsValid().error}
          id="btnAddProperty"
          data-testid="btnAddProperty">
          Add
        </Button>
      </div>
      <div className={styles.dividerSlim} />
      <Margin side="top" xs={12}>
        <Grid row>
          {values.map(value => (
            <Grid sm={3} key={`${value.property_name}-${value.property_value}`}>
              <div className={styles.badge}>
                <p className="m-0">
                  {value.property_name}
                  <span>
                    {value.property_value && <Typography component="strong">: {value.property_value}</Typography>}
                  </span>
                </p>

                <button
                  data-testid={`btnDeleteProperty-${value.property_name}`}
                  type="button"
                  className={styles.badgeDelete}
                  onClick={() => removeValue(value.property_name, value.property_value)}>
                  <Icon className="remixicon-delete-bin-line" />
                </button>
              </div>
            </Grid>
          ))}
        </Grid>
      </Margin>
    </>
  );
};

ManageValuesToVerify.propTypes = {
  checkIfPropertyIsValid: PropTypes.func,
  checkIfValueIsValid: PropTypes.func,
  setProperty: PropTypes.func,
  property: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.string,
  values: PropTypes.array,
  addValue: PropTypes.func,
  removeValue: PropTypes.func,
};

export default ManageValuesToVerify;

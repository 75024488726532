import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import {
  GET_RECORDS,
  GET_HEADERS,
  GET_SECRET,
  GET_CLIENT_ID,
  CREATE_RECORD,
  EDIT_RECORD,
  DELETE_RECORD,
} from './constants';

export const getRecords = extendRoutine(createRoutine(GET_RECORDS), 'RESET');
export const getHeaders = extendRoutine(createRoutine(GET_HEADERS), 'RESET');
export const getSecret = extendRoutine(createRoutine(GET_SECRET), 'RESET');
export const getClientId = extendRoutine(createRoutine(GET_CLIENT_ID), 'RESET');
export const createRecord = extendRoutine(createRoutine(CREATE_RECORD), 'RESET');
export const editRecord = extendRoutine(createRoutine(EDIT_RECORD), 'RESET');
export const deleteRecord = extendRoutine(createRoutine(DELETE_RECORD), 'RESET');

import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const TopicsContext = createContext();

function Topics({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <TopicsContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</TopicsContext.Provider>;
}

Topics.propTypes = {
  children: PropTypes.node,
};

export default Topics;

import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import {
  GET_VERIFICATIONS_MESSAGES_HISTORY,
  GET_DETAIL_VERIFICATION_MESSAGES,
  CREATE_VERIFICATION_MESSAGES,
  DELETE_VERIFICATION_MESSAGES,
} from './constants';

export const getVerificationMessagesHistory = extendRoutine(createRoutine(GET_VERIFICATIONS_MESSAGES_HISTORY), 'RESET');
export const getDetailVerificationMessages = extendRoutine(createRoutine(GET_DETAIL_VERIFICATION_MESSAGES), 'RESET');
export const createVerificationMessages = extendRoutine(createRoutine(CREATE_VERIFICATION_MESSAGES), 'RESET');
export const deleteVerificationMessages = extendRoutine(createRoutine(DELETE_VERIFICATION_MESSAGES), 'RESET');

import produce from 'immer';

import { getRecords, createRecord, editRecord, deleteRecord, getHeaders, getSecret, getClientId } from './actions';

export const initialState = {
  records: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  headers: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },

  secret: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },

  clientId: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },

  createRecord: {
    loading: false,
    loaded: false,
    error: null,
  },

  editRecord: {
    loading: false,
    loaded: false,
    error: null,
  },

  deleteRecord: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getRecords.REQUEST:
        draft.records.loading = true;
        draft.records.loaded = false;
        draft.records.error = null;
        break;

      case getRecords.SUCCESS:
        draft.records.loading = false;
        draft.records.loaded = true;
        draft.records.error = null;
        draft.records.list = payload;
        break;

      case getRecords.FAILURE:
        draft.records.loading = false;
        draft.records.loaded = false;
        draft.records.error = payload;
        break;

      case getRecords.RESET:
        draft.records = { ...initialState.records };
        break;

      case getHeaders.REQUEST:
        draft.headers.loading = true;
        draft.headers.loaded = false;
        draft.headers.error = null;
        break;

      case getHeaders.SUCCESS:
        draft.headers.loading = false;
        draft.headers.loaded = true;
        draft.headers.error = null;
        draft.headers.data = payload;
        break;

      case getHeaders.FAILURE:
        draft.headers.loading = false;
        draft.headers.loaded = false;
        draft.headers.error = payload;
        break;

      case getHeaders.RESET:
        draft.headers = { ...initialState.headers };
        break;

      case getSecret.REQUEST:
        draft.secret.loading = true;
        draft.secret.loaded = false;
        draft.secret.error = null;
        break;

      case getSecret.SUCCESS:
        draft.secret.loading = false;
        draft.secret.loaded = true;
        draft.secret.error = null;
        draft.secret.data = payload;
        break;

      case getSecret.FAILURE:
        draft.secret.loading = false;
        draft.secret.loaded = false;
        draft.secret.error = payload;
        break;

      case getSecret.RESET:
        draft.secret = { ...initialState.secret };
        break;

      case getClientId.REQUEST:
        draft.clientId.loading = true;
        draft.clientId.loaded = false;
        draft.clientId.error = null;
        break;

      case getClientId.SUCCESS:
        draft.clientId.loading = false;
        draft.clientId.loaded = true;
        draft.clientId.error = null;
        draft.clientId.data = payload;
        break;

      case getClientId.FAILURE:
        draft.clientId.loading = false;
        draft.clientId.loaded = false;
        draft.clientId.error = payload;
        break;

      case getClientId.RESET:
        draft.clientId = { ...initialState.clientId };
        break;

      case createRecord.REQUEST:
        draft.createRecord.loading = true;
        draft.createRecord.loaded = false;
        draft.createRecord.error = null;
        break;

      case createRecord.SUCCESS:
        draft.createRecord.loading = false;
        draft.createRecord.loaded = true;
        draft.createRecord.error = null;
        break;

      case createRecord.FAILURE:
        draft.createRecord.loading = false;
        draft.createRecord.loaded = false;
        draft.createRecord.error = payload;
        break;

      case createRecord.RESET:
        draft.createRecord = { ...initialState.createRecord };
        break;

      case editRecord.REQUEST:
        draft.editRecord.loading = true;
        draft.editRecord.loaded = false;
        draft.editRecord.error = null;
        break;

      case editRecord.SUCCESS:
        draft.editRecord.loading = false;
        draft.editRecord.loaded = true;
        draft.editRecord.error = null;
        draft.records.list =
          payload.paused !== undefined
            ? draft.records.list.map(item =>
                item.id === payload.recordId ? { ...item, paused: payload.paused } : item
              )
            : draft.records.list;
        break;

      case editRecord.FAILURE:
        draft.editRecord.loading = false;
        draft.editRecord.loaded = false;
        draft.editRecord.error = payload;
        break;

      case editRecord.RESET:
        draft.editRecord = { ...initialState.editRecord };
        break;

      case deleteRecord.REQUEST:
        draft.deleteRecord.loading = true;
        draft.deleteRecord.loaded = false;
        draft.deleteRecord.error = null;
        break;

      case deleteRecord.SUCCESS:
        draft.deleteRecord.loading = false;
        draft.deleteRecord.loaded = true;
        draft.deleteRecord.error = null;
        break;

      case deleteRecord.FAILURE:
        draft.deleteRecord.loading = false;
        draft.deleteRecord.loaded = false;
        draft.deleteRecord.error = payload;
        break;

      case deleteRecord.RESET:
        draft.deleteRecord = { ...initialState.deleteRecord };
        break;

      default:
        return draft;
    }
  });

export default reducer;

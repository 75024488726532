import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const SystemsContext = createContext();

function Systems({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <SystemsContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</SystemsContext.Provider>;
}

Systems.propTypes = {
  children: PropTypes.node,
};

export default Systems;

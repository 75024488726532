import { useEffect } from 'react';

import { signOut } from 'utils/auth';

function SignOut({ onSignOut }) {
  useEffect(() => {
    onSignOut();
  });

  return null;
}

SignOut.defaultProps = {
  onSignOut: signOut,
};

export default SignOut;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@gbm/queen-ui-guidelines';
import IconGbmLogo from './gbm.png';

import styles from './styles.module.scss';

function NotFound(props) {
  const { history } = props;

  const handleClick = () => {
    history.push('/');
  };

  return (
    <main className={styles.notFoundContainer}>
      <figure>
        <img src={IconGbmLogo} className="img-fluid" alt="GBM Logo" />
      </figure>
      <h1>Oops! Page not found</h1>
      <Button size="lg" color="success" onClick={handleClick}>
        Home
      </Button>
    </main>
  );
}

NotFound.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
};

export default NotFound;

export const METHODS = [
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'PATCH', label: 'PATCH' },
  { value: 'DELETE', label: 'DELETE' },
];

export const LOCAL_STORAGE = {
  TOKEN: 'munus_token',
  REFRESH_TOKEN: 'munus_refresh_token',
  LAST_TIME_ACTIVE: 'munus_last_time_active',
  ROLE_ID: 'munus_role_id',
};

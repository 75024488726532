import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Icon, Dropdown, DropdownItem } from '@gbm/queen-ui-guidelines';

import { getTokenUsername } from 'utils/auth';

import './styles.scss';

class Navbar extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onSmall: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      messagesDropdownOpen: false,
      tasksDropdownOpen: false,
      alertsDropdownOpen: false,
      userDropdownOpen: false,
    };
  }

  handleToggleDropdown = () => {
    const { userDropdownOpen } = this.state;
    this.setState({
      userDropdownOpen: !userDropdownOpen,
    });
  };

  handleCloseDropdown = () => {
    this.setState({
      userDropdownOpen: false,
    });
  };

  render() {
    const { title, onSmall } = this.props;
    const { userDropdownOpen } = this.state;
    const userName = getTokenUsername();

    return (
      <nav className="navbar navbar-light bg-faded" role="toolbar">
        <div className="navbar-brand">
          <div data-testid="btnLogoMunus" className="logo-munus" onClick={onSmall}>
            M
          </div>
          <Link className="navbar-label" to="/">
            {title}
          </Link>
        </div>

        <Dropdown
          data-testid="dropdownUserLogout"
          variant="button"
          right
          className="dropdown-navbar"
          label={userName}
          color="light"
          onOpen={this.handleToggleDropdown}
          onClose={this.handleCloseDropdown}
          open={userDropdownOpen}
          iconButton={<Icon className={`fas fa-chevron-${userDropdownOpen ? 'up' : 'down'} ml-1`} />}>
          <DropdownItem data-testid="btnLogout" className="sign-out">
            <Link to="/sign-out" className="dropdown-item notify-item">
              <Icon className="remixicon-logout-box-line mr-2" />
              <span>Logout</span>
            </Link>
          </DropdownItem>
        </Dropdown>
      </nav>
    );
  }
}

export default Navbar;

import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@gbm/queen-ui-guidelines';

function VerificationDeleteConfirmation({ open, onClose, onSubmit, loading, verificationToDelete }) {
  return (
    <Modal isOpen={open} centered id="modalDeleteVerification" data-testid="modalDeleteVerification">
      <ModalHeader onClose={onClose}>Are you sure?</ModalHeader>
      <ModalBody>
        <div className="form-group">
          <label>{`The following verification will be deleted from our records. This process can't be undone:`}</label>
          <label>{verificationToDelete}</label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          id="btnCancelDeletion"
          data-testid="btnCancelDeletion"
          color="secondary"
          disabled={loading}
          onClick={onClose}>
          Close
        </Button>

        <Button
          id="btnConfirmDeletion"
          data-testid="btnConfirmDeletion"
          color="danger"
          disabled={loading}
          loading={loading}
          onClick={onSubmit}>
          {loading ? 'Deleting' : 'Delete'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

VerificationDeleteConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  verificationToDelete: PropTypes.string,
};

export default VerificationDeleteConfirmation;

import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Margin,
  CardBox,
  Typography,
  CopyToClipboard,
  Skeleton,
  DisplayMessage,
} from '@gbm/queen-ui-guidelines';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { getFormatDateTime } from 'utils/dates';
import { useDispatcherVerificationMessages } from 'providers/VerificationMessages/useDispatcher';
import { useSelectorsVerificationMessages } from 'providers/VerificationMessages/useSelectors';
import PageError from 'components/PageError';
const DetailVerificationMessageModal = ({ onClose, open, verificationId }) => {
  const { getDetailVerificationMessages } = useDispatcherVerificationMessages();
  const { detailVerificationMessagesState } = useSelectorsVerificationMessages();
  const { verificationDetail, loading, error, loaded } = detailVerificationMessagesState;

  useEffect(() => {
    getDetailVerificationMessages(verificationId);
  }, [verificationId]);

  const getValuesOfMessage = data => {
    return Object.entries(data).map(values => {
      const property = values[0];
      const value = JSON.stringify(values[1]);
      return (
        <Margin side="bottom" xs={12} key={`${values[0]}-${values[1]}`}>
          <Typography component="strong" variant="subHeader">
            - {property}
          </Typography>

          <Typography monospace className={styles.dontBreak}>
            : {value}
          </Typography>
        </Margin>
      );
    });
  };

  const renderMessages = () => {
    if (loaded && !verificationDetail?.partialFoundMessages) {
      return (
        <DisplayMessage
          id="msgEmptyInfo"
          data-testid="msgEmptyInfo"
          variant="info"
          message="There are not messages found"
        />
      );
    }
    return (
      <Margin side="bottom" xs={24}>
        <Typography variant="header" component="h5">
          Messages (The messages shown are only a sample of 10 of the total obtained).
        </Typography>
        {verificationDetail?.partialFoundMessages?.map(message => (
          <Margin side="bottom" xs={12} key={Math.random()}>
            <Grid row>
              <Grid sm={6}>
                <Typography variant="subHeader" component="h5">
                  Properties
                </Typography>
                {getValuesOfMessage(message.body)}
              </Grid>
              <Grid sm={6}>
                <Typography variant="subHeader" component="h5">
                  Headers
                </Typography>
                {getValuesOfMessage(message.headers)}
              </Grid>
            </Grid>
            <div className={styles.dividerSlim}></div>
          </Margin>
        ))}
      </Margin>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Grid container>
          <Margin side="bottom" xs={24}>
            <Grid row>
              <Grid sm={5}>
                <CardBox className="h-100">
                  <Margin side="bottom" xs={12}>
                    <Grid row>
                      <Grid sm={12}>
                        <Skeleton height={25} width={120} />
                      </Grid>
                    </Grid>
                  </Margin>
                  <Grid row>
                    <Grid sm={12}>
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                    </Grid>
                  </Grid>
                </CardBox>
              </Grid>

              <Grid sm={7}>
                <CardBox className="h-100">
                  <Margin side="bottom" xs={12}>
                    <Grid row>
                      <Grid sm={6}>
                        <Skeleton height={25} width={120} />
                      </Grid>
                      <Grid sm={6}>
                        <Skeleton height={25} width={120} />
                      </Grid>
                    </Grid>
                  </Margin>

                  <Grid row>
                    <Grid sm={6}>
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                    </Grid>
                    <Grid sm={6}>
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                    </Grid>
                  </Grid>
                </CardBox>
              </Grid>
            </Grid>
          </Margin>
          <Grid row>
            <Grid sm={12}>
              <CardBox>
                <Grid row>
                  <Grid sm={6}>
                    <Skeleton height={25} width={120} />
                    <Skeleton height={25} />
                    <Skeleton height={25} />
                  </Grid>
                  <Grid sm={6}>
                    <Skeleton height={25} width={120} />
                    <Skeleton height={25} />
                    <Skeleton height={25} />
                  </Grid>
                </Grid>
                <Grid row>
                  <Grid sm={6}>
                    <Skeleton height={25} width={120} />
                    <Skeleton height={25} />
                    <Skeleton height={25} />
                  </Grid>
                  <Grid sm={6}>
                    <Skeleton height={25} width={120} />
                    <Skeleton height={25} />
                    <Skeleton height={25} />
                  </Grid>
                </Grid>
              </CardBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (loaded && error) {
      return (
        <PageError
          id="msgVerificationPageError"
          message="¡Oops! The information could not be loaded"
          labelAction="Retry"
          onAction={getDetailVerificationMessages}
        />
      );
    }

    return (
      <Grid container>
        <Margin side="bottom" xs={24}>
          <Grid row>
            <Grid sm={5}>
              <CardBox className="h-100">
                <Margin side="bottom" xs={24}>
                  <Grid row>
                    <Grid sm={12}>
                      <Typography variant="header" component="h5">
                        Detail Verification
                      </Typography>
                      <Margin side="bottom" xs={12}>
                        <Typography component="strong" variant="subHeader">
                          ID:{' '}
                        </Typography>
                        <Typography monospace className={styles.dontBreak}>
                          <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                            {verificationDetail?.id}
                          </CopyToClipboard>
                        </Typography>
                      </Margin>
                      <Margin side="bottom" xs={12}>
                        <Typography component="strong" variant="subHeader">
                          Instance size:{' '}
                        </Typography>
                        <Typography monospace className={styles.dontBreak}>
                          {verificationDetail?.instanceType ?? 'N/A'}
                        </Typography>
                      </Margin>
                      <Margin side="bottom" xs={12}>
                        <Typography component="strong" variant="subHeader">
                          Topic:{' '}
                        </Typography>
                        <Typography monospace className={styles.dontBreak}>
                          <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                            {verificationDetail?.topic}
                          </CopyToClipboard>
                        </Typography>
                      </Margin>
                      <Margin side="bottom" xs={12}>
                        <Typography component="strong" variant="subHeader">
                          Start Date:{' '}
                        </Typography>
                        <Typography monospace className={styles.dontBreak}>
                          <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                            {getFormatDateTime(verificationDetail?.startDate ?? '2020-01-01')}
                          </CopyToClipboard>
                        </Typography>
                      </Margin>
                      <Margin side="bottom" xs={12}>
                        <Typography component="strong" variant="subHeader">
                          End Date:{' '}
                        </Typography>
                        <Typography monospace className={styles.dontBreak}>
                          <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                            {getFormatDateTime(verificationDetail?.endDate ?? '2020-01-01')}
                          </CopyToClipboard>
                        </Typography>
                      </Margin>
                    </Grid>
                  </Grid>
                </Margin>
              </CardBox>
            </Grid>
            <Grid sm={7}>
              <CardBox className="h-100">
                <Margin side="bottom" xs={24}>
                  <Grid row key={2}>
                    <Grid sm={6}>
                      <Typography variant="" component="h5">
                        Properties
                      </Typography>
                      {verificationDetail?.bodyProperties?.map(property => (
                        <Margin side="bottom" xs={3} key={`${property.propertyName}-${property.propertyValue}`}>
                          <Typography component="strong" variant="subHeader">
                            - {property.propertyName}
                          </Typography>

                          {property.propertyValue && (
                            <Typography monospace className={styles.dontBreak}>
                              : {property.propertyValue}
                            </Typography>
                          )}
                        </Margin>
                      ))}
                    </Grid>
                    <Grid sm={6}>
                      <Typography variant="header" component="h5">
                        Headers
                      </Typography>
                      {verificationDetail?.headerProperties?.organizationId && (
                        <Margin side="bottom" xs={12}>
                          <Typography component="strong" variant="subHeader">
                            Corporation id:{' '}
                          </Typography>
                          <Typography monospace className={styles.dontBreak}>
                            <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                              {verificationDetail?.headerProperties?.organizationId}
                            </CopyToClipboard>
                          </Typography>
                        </Margin>
                      )}
                      {verificationDetail?.headerProperties?.eventType && (
                        <Margin side="bottom" xs={12}>
                          <Typography component="strong" variant="subHeader">
                            Event type:{' '}
                          </Typography>
                          <Typography monospace className={styles.dontBreak}>
                            <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                              {verificationDetail?.headerProperties?.eventType}
                            </CopyToClipboard>
                          </Typography>
                        </Margin>
                      )}

                      {verificationDetail?.headerProperties?.eventVersion && (
                        <Margin side="bottom" xs={12}>
                          <Typography component="strong" variant="subHeader">
                            Event version:{' '}
                          </Typography>
                          <Typography monospace className={styles.dontBreak}>
                            <CopyToClipboard messageBeforeCopy="Copy" messageAfterCopy="Copied!">
                              {verificationDetail?.headerProperties?.eventVersion}
                            </CopyToClipboard>
                          </Typography>
                        </Margin>
                      )}
                    </Grid>
                  </Grid>
                </Margin>
              </CardBox>
            </Grid>
          </Grid>
        </Margin>

        <Grid row>
          <Grid sm={12}>
            <CardBox>{renderMessages()}</CardBox>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      isOpen={open}
      size="xl"
      centered
      scrollable
      id="modalDetailVerification"
      data-testid="modalDetailVerification">
      <ModalHeader onClose={onClose} className={styles.modalBody}>
        Detail Verification Messages
      </ModalHeader>
      <ModalBody className={styles.modalBody}>{renderContent()}</ModalBody>
      <ModalFooter className={styles.modalBody}>
        <Button
          onClick={onClose}
          size="sm"
          color="secondary"
          id="btnCloseDetailVerificationModal"
          data-testid="btnCloseDetailVerificationModal">
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DetailVerificationMessageModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  verificationId: PropTypes.string,
};

export default DetailVerificationMessageModal;

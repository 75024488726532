import { useContext } from 'react';

import { VerifyMessagesContext } from './index';

export function useSelectorsVerificationMessages() {
  const { state } = useContext(VerifyMessagesContext);

  return {
    verificationMessagesState: state.verifyMessages,
    detailVerificationMessagesState: state.detailVerificationMessages,
    createVerificationMessagesState: state.createVerificationMessages,
    deleteVerificationMessagesState: state.deleteVerificationMessages,
  };
}

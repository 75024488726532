import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const InstanceContext = createContext();

function Instances({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <InstanceContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</InstanceContext.Provider>
  );
}

Instances.propTypes = {
  children: PropTypes.node,
};

export default Instances;

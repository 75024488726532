import decode from 'jwt-decode';
import camelCase from 'lodash/camelCase';

import { envConfig } from 'config';
import request from 'utils/request';
import { getUserRequest } from 'utils/request/users';

import { LOCAL_STORAGE } from 'utils/constants';

const {
  authClientId,
  authRedirectLoginUrl,
  authRedirectLogoutUrl,
  authUrl,
  grantType,
  grantTypeRefreshToken,
  login,
  logout,
  token: tokenConfig,
} = envConfig.CONFIG_DEFAULT_COGNITO;

export const INACTIVE_TIMEOUT = 3000;
export const WARNING_TIME_S = 1000;
export const LOGOUT_MINUTES = 5;

export function getTokenRequest({ code }) {
  return request(
    `${authUrl}${tokenConfig}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        grant_type: grantType,
        client_id: authClientId,
        redirect_uri: authRedirectLoginUrl,
        code,
      },
    },
    false
  );
}

export function refreshTokenRequest(refreshToken) {
  return request(
    `${authUrl}${tokenConfig}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: {
        grant_type: grantTypeRefreshToken,
        client_id: authClientId,
        refresh_token: refreshToken,
      },
    },
    false
  );
}

export function getTokenUsername() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const decoded = decode(token);

  return decoded.given_name || decoded['cognito:username'] || null;
}

export function getCognitoUsername() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const decodeMapping = decode(token);

  const cognitoUser = decodeMapping['cognito:username'];
  return cognitoUser?.replace('GBM_', '');
}

export function getTokenExpirationDate(token) {
  if (!token) {
    return null;
  }

  const date = new Date(0);

  date.setUTCSeconds(decode(token).exp);
  return date;
}

export async function getRoleToken() {
  const roleId = localStorage.getItem(LOCAL_STORAGE.ROLE_ID);
  if (roleId) {
    return roleId;
  } else {
    const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
    const { sub } = decode(token);
    const {
      items: [{ role }],
    } = await getUserRequest(sub);
    return role || null;
  }
}

export function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  const isTokenExpired = expirationDate === null ? false : !(expirationDate.valueOf() > new Date().valueOf());

  return isTokenExpired;
}

export function isSessionExpired() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  return token && isTokenExpired(token);
}

export function onIdleTimeout() {
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  if (!token) {
    return false;
  }

  const decodeMapping = decode(token);
  const seconds = 1000;
  const minutes = 60;
  let time = (+new Date(decodeMapping.exp * seconds) - +new Date()) / seconds / minutes;

  return parseInt(time) <= LOGOUT_MINUTES;
}

export function setActive() {
  const now = Date.now();
  localStorage.setItem(LOCAL_STORAGE.LAST_TIME_ACTIVE, now);
}

export function shouldWarn() {
  const key = localStorage.getItem(LOCAL_STORAGE.LAST_TIME_ACTIVE) || 0;
  return parseInt(key, 10) > 0;
}

export function redirectToLogin() {
  return window.location.replace(
    `${authUrl}${login}client_id=${authClientId}&response_type=code&scope=openid+aws.cognito.signin.user.admin+profile&redirect_uri=${authRedirectLoginUrl}`
  );
}

export function signOut() {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  localStorage.removeItem(LOCAL_STORAGE.ROLE_ID);
  return window.location.replace(`${authUrl}${logout}client_id=${authClientId}&logout_uri=${authRedirectLogoutUrl}`);
}

export const getPermissionsList = userPermissions => {
  const getValuesCamelCase = values => values.map(element => camelCase(element));

  return Object.entries(userPermissions).reduce((permissions, [permissionKey, permissionValue]) => {
    permissions.push(...getValuesCamelCase(permissionValue), permissionKey);
    return permissions;
  }, []);
};

import { envConfig } from 'config';
import request from 'utils/request';

const API_BASE_URL = `${envConfig.API_BASE}/api`;

export function getVerificationMessagesRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/verification`, {
    method: 'GET',
    ...options,
  });
}

export function getDetailVerificationMessagesRequest(verificationId, options = {}) {
  return request(
    `${API_BASE_URL}/v1/verification/${verificationId}`,
    {
      method: 'GET',
      ...options,
    },
    true,
    false
  );
}

export function createVerificationMessagesRequest(bodyRequest, options = {}) {
  return request(`${API_BASE_URL}/v1/verification`, {
    method: 'POST',
    body: bodyRequest,
    ...options,
  });
}

export function deleteVerificationMessagesRequest({ verificationId }, options = {}) {
  return request(`${API_BASE_URL}/v1/verification/${verificationId}`, {
    method: 'DELETE',
    ...options,
  });
}

import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const VerifyMessagesContext = createContext();

function VerificationMessages({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <VerifyMessagesContext.Provider value={{ state, dispatch }}>
      {React.Children.only(children)}
    </VerifyMessagesContext.Provider>
  );
}

VerificationMessages.propTypes = {
  children: PropTypes.node,
};

export default VerificationMessages;

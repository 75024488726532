import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import Records from 'containers/Records/Loadable';
import IndividualInstances from 'containers/IndividualInstances/Loadable';
import Instances from 'containers/Instances/Loadable';
import NotFound from 'containers/NotFound';
import SignIn from 'containers/SignIn';
import SignOut from 'containers/SignOut';
import AccessManagement from 'containers/AccessManagement/Loadable';
import CatalogsManagement from 'containers/CatalogsManagement/Loadable';

import ROUTES from 'utils/routes';
import VerifyMessages from 'containers/VerifyMessages';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
        <PrivateRoute exact path={ROUTES.RECORDS} component={Records} />
        <PrivateRoute exact path={ROUTES.INDIVIDUAL_INSTANCES} component={IndividualInstances} />
        <PrivateRoute exact path={ROUTES.GENERAL_INSTANCES} component={Instances} />
        <PrivateRoute exact path={ROUTES.SIGN_OUT} component={SignOut} />
        <PrivateRoute exact path={ROUTES.ACCESS_MANAGEMENT} component={AccessManagement} />
        <PrivateRoute exact path={ROUTES.CATALOGS_MANAGEMENT} component={CatalogsManagement} />
        <PrivateRoute exact path={ROUTES.VERIFY_MESSAGES} component={VerifyMessages} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;

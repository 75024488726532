import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const IndividualInstancesContext = createContext();

function IndividualInstances({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <IndividualInstancesContext.Provider value={{ state, dispatch }}>
      {React.Children.only(children)}
    </IndividualInstancesContext.Provider>
  );
}

IndividualInstances.propTypes = {
  children: PropTypes.node,
};

export default IndividualInstances;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, CardBox, Icon, Notification, Skeleton } from '@gbm/queen-ui-guidelines';
import { RenderFeature, useFeature, withFeature } from 'providers/FeatureProvider';
import styles from './styles.module.scss';
import CreateVerificationMessageModal from './CreateVerificationMessageModal';
import { useSelectorsSystems } from 'providers/Systems/useSelectors';
import { useDispatcherSystems } from 'providers/Systems/useDispatcher';
import { useDispatcherTopics } from 'providers/Topics/useDispatcher';
import { useSelectorsTopics } from 'providers/Topics/useSelectors';
import { useDispatcherVerificationMessages } from 'providers/VerificationMessages/useDispatcher';
import { useSelectorsVerificationMessages } from 'providers/VerificationMessages/useSelectors';
import TableHistoryOfVerifications from './TableHistoryOfVerifications';
import DetailVerificationMessageModal from './DetailVerificationMessageModal';
import VerificationDeleteConfirmation from './Dialogs/VerificationDeleteConfirmation';

const VerifyMessages = () => {
  const [verificationDetail, setVerificationDetail] = useState(null);
  const [verificationToDelete, setVerificationToDelete] = useState(null);
  const [isOpenCreateVerificationMessageModal, setIsOpenCreateVerificationMessageModal] = useState(false);
  const [isOpenDetailVerificationMessageModal, setIsOpenDetailVerificationMessageModal] = useState(false);
  const [isOpenDeleteVerificationDialog, setIsOpenDeleteVerificationDialog] = useState(false);

  const { systemsState } = useSelectorsSystems();
  const loading = false;
  const { getSystems, resetGetSystems } = useDispatcherSystems();
  const { getTopics, resetGetTopics } = useDispatcherTopics();
  const { topicsState } = useSelectorsTopics();
  const {
    getVerificationMessagesHistory,
    createVerificationMessages,
    deleteVerificationMessages,
    resetGetVerificationMessagesHistory,
    resetCreateVerificationMessages,
    resetDeleteVerificationMessages,
  } = useDispatcherVerificationMessages();

  const { verificationMessagesState, createVerificationMessagesState, deleteVerificationMessagesState } =
    useSelectorsVerificationMessages();

  const canListVerificationsMessages = useFeature('verificationMessagesRead');

  useEffect(() => {
    getVerificationMessagesHistory();
    return () => {
      resetGetVerificationMessagesHistory();
      resetCreateVerificationMessages();
      resetDeleteVerificationMessages();
    };
  }, [
    getVerificationMessagesHistory,
    resetGetVerificationMessagesHistory,
    resetCreateVerificationMessages,
    resetDeleteVerificationMessages,
  ]);

  useEffect(() => {
    getSystems();

    return resetGetSystems();
  }, [getSystems, resetGetSystems]);

  useEffect(() => {
    if (createVerificationMessagesState.loaded) {
      getVerificationMessagesHistory();
      setIsOpenCreateVerificationMessageModal(false);
    }
  }, [createVerificationMessagesState.loaded, getVerificationMessagesHistory]);

  useEffect(() => {
    if (deleteVerificationMessagesState.loaded) {
      getVerificationMessagesHistory();
      setIsOpenDeleteVerificationDialog(false);
    }
  }, [deleteVerificationMessagesState.loaded, getVerificationMessagesHistory]);

  const handleDeleteVerification = verification => {
    setVerificationToDelete(verification);
    setIsOpenDeleteVerificationDialog(true);
  };

  const handleDetailVerification = verification => {
    setVerificationDetail(verification);
    setIsOpenDetailVerificationMessageModal(true);
  };

  const handleCreateVerification = (values, properties) => {
    values.isFilterSet = false;
    if (properties.length > 0) {
      values = { ...values, propertiesFilter: [...properties], isFilterSet: true };
    }

    if (values.eventType || values.eventVersion || values.organizationId) {
      let valuesFilter = {
        headers: {},
      };

      if (values.eventType) {
        valuesFilter.headers = { ...valuesFilter.headers, event_type: values.eventType };
      }

      if (values.eventVersion) {
        valuesFilter.headers = { ...valuesFilter.headers, event_version: values.eventVersion };
      }

      if (values.organizationId) {
        valuesFilter.headers = { ...valuesFilter.headers, org_id: values.organizationId };
      }

      values = { ...values, valuesFilter: { ...valuesFilter }, isFilterSet: true };
    }
    createVerificationMessages(values);
  };
  return (
    <>
      <div className={styles.records}>
        <div className="content-fluid">
          <div className={styles.row}>
            <div className={styles.title}>Verify Messages</div>
          </div>

          <CardBox>
            <div className={styles.row}>
              <div className={clsx(styles.alignRight, styles.actionButtons)}>
                <RenderFeature name="verificationMessagesRead">
                  {loading ? (
                    <Skeleton width={120} height={30} />
                  ) : (
                    <Button
                      id="btnRefreshVerifications"
                      data-testid="btnRefreshVerifications"
                      size="sm"
                      onClick={() => getVerificationMessagesHistory()}>
                      <Icon className={clsx(styles.alignBottom, 'remixicon-refresh-line')} />
                      <span>Refresh</span>
                    </Button>
                  )}
                </RenderFeature>

                <RenderFeature name="verificationMessagesCreate">
                  {loading ? (
                    <Skeleton width={120} height={30} />
                  ) : (
                    <Button
                      id="btnCreateVerification"
                      data-testid="btnCreateVerification"
                      size="sm"
                      onClick={() => setIsOpenCreateVerificationMessageModal(true)}>
                      +<span>Create verification</span>
                    </Button>
                  )}
                </RenderFeature>
              </div>
            </div>
            {canListVerificationsMessages && (
              <TableHistoryOfVerifications
                error={Boolean(verificationMessagesState.error)}
                loaded={verificationMessagesState.loaded}
                loading={verificationMessagesState.loading}
                verificationsList={verificationMessagesState.list}
                onReload={getVerificationMessagesHistory}
                handleDetailVerification={handleDetailVerification}
                handleDeleteVerification={handleDeleteVerification}
              />
            )}
          </CardBox>
        </div>
      </div>

      {isOpenCreateVerificationMessageModal && (
        <CreateVerificationMessageModal
          open={isOpenCreateVerificationMessageModal}
          onClose={() => {
            setIsOpenCreateVerificationMessageModal(false);
          }}
          systemsList={systemsState.list}
          getTopics={getTopics}
          topicsState={topicsState}
          resetGetTopics={resetGetTopics}
          loading={createVerificationMessagesState.loading}
          onSubmit={(values, properties) => handleCreateVerification(values, properties)}
        />
      )}
      {isOpenDetailVerificationMessageModal && (
        <DetailVerificationMessageModal
          open={isOpenDetailVerificationMessageModal}
          onClose={() => {
            setIsOpenDetailVerificationMessageModal(false);
          }}
          verificationId={verificationDetail.id}
        />
      )}

      {isOpenDeleteVerificationDialog && (
        <VerificationDeleteConfirmation
          open={isOpenDeleteVerificationDialog}
          onClose={() => {
            setIsOpenDeleteVerificationDialog(false);
            resetDeleteVerificationMessages();
          }}
          onSubmit={() => deleteVerificationMessages({ verificationId: verificationToDelete.id })}
          loading={deleteVerificationMessagesState.loading}
          verificationToDelete={verificationToDelete.topic}
        />
      )}

      <Notification
        id="notificationCreateVerificationSuccess"
        data-testid="notificationCreateVerificationSuccess"
        open={createVerificationMessagesState.loaded}
        color="success"
        colorInherit>
        The messages verification process was created successfully, you should wait until the process finish to watch
        the results.
      </Notification>
      <Notification
        id="notificationCreateVerificationError"
        data-testid="notificationCreateVerificationError"
        open={Boolean(createVerificationMessagesState.error)}
        color="danger"
        colorInherit>
        It was not possible to create the messages verification process. Please, try again.
      </Notification>

      <Notification
        id="notificationDeleteVerificationSuccess"
        data-testid="notificationDeleteVerificationSuccess"
        open={deleteVerificationMessagesState.loaded}
        color="success"
        colorInherit>
        The messages verification process was deleted successfully.
      </Notification>
      <Notification
        id="notificationDeleteVerificationError"
        data-testid="notificationDeleteVerificationError"
        open={Boolean(deleteVerificationMessagesState.error)}
        color="danger"
        colorInherit>
        It was not possible to delete the messages verification process. Please, try again.
        {deleteVerificationMessagesState.error}
      </Notification>
    </>
  );
};

export default withFeature('verificationMessagesRead')(VerifyMessages);

import {
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Typography,
  Margin,
  Input,
  Notification,
} from '@gbm/queen-ui-guidelines';
import {
  eventTypeInput,
  eventVersionInput,
  organizationIdInput,
  startDateInput,
  endDateInput,
  topicDropdown,
  systemDropdown,
  instanceSizeDropdown,
} from 'utils/records';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ManageValuesToVerify from 'components/ManageValuesToVerify';
import DropdownController from 'components/DropdownController';
import { useForm, Controller } from 'react-hook-form';
import styles from './styles.module.scss';
import { getCurrentDate } from 'utils/dates';
import { systemsOptions } from 'utils/systems';
import moment from 'moment-timezone';
import { propertyObjectRegex } from 'utils/validations';
import { envConfig } from 'config';
import { instanceSizeOptions } from 'utils/instanceSize';

const CreateVerificationMessageModal = ({
  onClose,
  open,
  topicsState,
  getTopics,
  resetGetTopics,
  systemsList,
  onSubmit,
  loading,
}) => {
  const [propertyToShare, setPropertyToShare] = useState('');
  const [valueToShare, setValueToShare] = useState('');
  const [propertiesToShare, setPropertiesToShare] = useState([]);
  const [topicsListBySystem, setTopicListBySystem] = useState([]);
  const [errorInForm, setErrorInForm] = useState('');

  const todayDate = getCurrentDate();

  useEffect(() => {
    getTopics();

    return () => {
      resetGetTopics();
    };
  }, [getTopics, resetGetTopics]);

  const { handleSubmit, formState, register, errors, control, watch, setValue, trigger } = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      [systemDropdown.name]: '',
      [startDateInput.name]: todayDate,
      [endDateInput.name]: todayDate,
      [instanceSizeDropdown.name]: instanceSizeOptions.find(option => option.value === 'MEDIUM').value,
    },
  });

  let watchFields = watch([systemDropdown.name, topicDropdown.name, startDateInput.name, endDateInput.name]);

  const filterTopics = filterValue => {
    return topicsState.list.filter(({ name }) => name.split('.')[0] === filterValue);
  };

  const addProperty = () => {
    if (propertyToShare) {
      const values = { property_name: propertyToShare, property_value: valueToShare || null };
      setPropertiesToShare(properties => [...properties, values]);
      setPropertyToShare('');
      setValueToShare('');
    }
  };

  const removeProperty = (propertyName, propertyValue) => {
    const indexToRemove = propertiesToShare.findIndex(
      p => p.property_name === propertyName && p.property_value === propertyValue
    );
    const propertiesToShareCopy = [...propertiesToShare];
    propertiesToShareCopy.splice(indexToRemove, 1);
    setPropertiesToShare([...propertiesToShareCopy]);
  };

  const checkIfPropertyIsValid = valueToVerify => {
    let valueState = { error: false, message: '' };
    if (valueToVerify) {
      const regex = new RegExp(propertyObjectRegex);
      if (!regex.test(valueToVerify)) {
        valueState.error = true;
        valueState.message = 'Special chars are not allowed.';
      } else if (propertiesToShare.some(property => property.property_name === valueToVerify)) {
        valueState.error = true;
        valueState.message = 'The property has been added.';
      }
    }

    return valueState;
  };

  const checkIfValueIsValid = valueToVerify => {
    let valueState = { error: false, message: '' };
    if (valueToVerify) {
      if (propertiesToShare.some(property => property.property_name === valueToVerify)) {
        valueState.error = true;
        valueState.message = 'The property has been added';
      }
    }

    return valueState;
  };
  return (
    <>
      <Modal
        isOpen={open}
        size="xl"
        centered
        scrollable
        id="modalCreateVerification"
        data-testid="modalCreateVerification">
        <ModalHeader onClose={onClose}>Create Verification Messages process </ModalHeader>
        <ModalBody>
          <Grid container>
            {topicsState.loading ? (
              <div className={styles.spinnerCenter}>
                <Spinner size="md" color="info" variant="border" />
              </div>
            ) : (
              <>
                <Grid>
                  <Margin side="bottom" xs={24}>
                    <Typography>
                      If you want to check the properties or headers about topic you can acces to{' '}
                      <a href={envConfig.TORAH_DOCUMENTATION} target="__blank">
                        Torah documentation
                      </a>
                      .
                    </Typography>
                  </Margin>
                  <Margin side="bottom" xs={24}>
                    <Margin side="bottom" xs={24}>
                      <Typography data-testid="textDescriptionOfHeaderFilter">
                        Select the information of the system and topic that you want to verify and a range of dates.
                      </Typography>
                    </Margin>
                    <Margin side="bottom" xs={24}>
                      <Grid row>
                        <Grid sm={3}>
                          <Margin side="bottom" xs={24}>
                            <Controller
                              control={control}
                              name={systemDropdown.name}
                              rules={{ required: systemDropdown.required }}
                              render={({ onChange, onBlur, value, name }) => (
                                <DropdownController
                                  error={!!errors[name]}
                                  errorMsg={errors[name]?.message}
                                  id={systemDropdown.id}
                                  label={systemDropdown.label}
                                  name={name}
                                  onBlur={onBlur}
                                  onChange={e => {
                                    onChange(e.value);
                                    setTopicListBySystem(filterTopics(e.value));
                                    setValue(topicDropdown.name, null);
                                    trigger(topicDropdown.name);
                                  }}
                                  options={systemsOptions(systemsList)}
                                  placeholder={
                                    systemsList.find(option => option.name === value)?.friendlyName ||
                                    systemDropdown.placeholder
                                  }
                                  required={systemDropdown.required}
                                  className={styles.systemDropdown}
                                />
                              )}
                            />
                          </Margin>
                        </Grid>
                        <Grid sm={3}>
                          <Margin side="bottom" xs={24}>
                            <Controller
                              control={control}
                              name={topicDropdown.name}
                              rules={{ required: topicDropdown.required }}
                              render={({ onChange, onBlur, value, name }) => (
                                <DropdownController
                                  id={topicDropdown.id}
                                  label={topicDropdown.label}
                                  name={name}
                                  onBlur={onBlur}
                                  onChange={e => {
                                    onChange(e.name);
                                  }}
                                  options={topicsListBySystem}
                                  placeholder={
                                    topicsListBySystem?.find(option => option.name === value)?.name ||
                                    topicDropdown.placeholder
                                  }
                                  required={topicDropdown.required}
                                  className={styles.systemDropdown}
                                  disabled={!(watchFields.system.length && topicsListBySystem.length)}
                                />
                              )}
                            />
                          </Margin>
                        </Grid>

                        <Grid sm={3}>
                          <Margin side="bottom" xs={24}>
                            <Input
                              className={styles.inputDate}
                              id={startDateInput.id}
                              data-testid={startDateInput.id}
                              name={startDateInput.name}
                              type="datetime-local"
                              label={<Typography>{startDateInput.label}</Typography>}
                              placeholder={startDateInput.placeholder}
                              required={startDateInput.required}
                              ref={register(startDateInput.register)}
                              error={!!errors[startDateInput.name]}
                              errorMsg={errors[startDateInput.name]?.message}
                              max={todayDate}
                            />
                          </Margin>
                        </Grid>
                        <Grid sm={3}>
                          <Margin side="bottom" xs={24}>
                            <Input
                              className={styles.inputDate}
                              id={endDateInput.id}
                              data-testid={endDateInput.id}
                              name={endDateInput.name}
                              type="datetime-local"
                              label={<Typography>{endDateInput.label}</Typography>}
                              placeholder={endDateInput.placeholder}
                              required={endDateInput.required}
                              ref={register(endDateInput.register)}
                              error={!!errors[endDateInput.name]}
                              errorMsg={errors[endDateInput.name]?.message}
                              min={new Date(watchFields[startDateInput.name]) ?? todayDate}
                              max={todayDate}
                            />
                          </Margin>
                        </Grid>
                      </Grid>
                    </Margin>
                    <div className={styles.dividerSlim} />
                  </Margin>
                  <Margin side="bottom" xs={24}>
                    <Margin side="bottom" xs={24}>
                      <Typography data-testid="textDescriptionOfHeaderFilter">
                        Add the vale of headers that you want to verify in messages.
                      </Typography>
                    </Margin>
                    <Margin side="bottom" xs={24}>
                      <Grid row>
                        <Grid sm={4}>
                          <Margin side="bottom" xs={24}>
                            <Input
                              data-testid={eventTypeInput.id}
                              error={!!errors[eventTypeInput.name]}
                              errorMsg={errors[eventTypeInput.name]?.message}
                              id={eventTypeInput.id}
                              label={eventTypeInput.label}
                              name={eventTypeInput.name}
                              placeholder={eventTypeInput.placeholder}
                              ref={register(eventTypeInput.register)}
                              required={eventTypeInput.required}
                              type="text"
                            />
                          </Margin>
                        </Grid>
                        <Grid sm={4}>
                          <Margin side="bottom" xs={24}>
                            <Input
                              data-testid={eventVersionInput.id}
                              error={!!errors[eventVersionInput.name]}
                              errorMsg={errors[eventVersionInput.name]?.message}
                              id={eventVersionInput.id}
                              label={eventVersionInput.label}
                              name={eventVersionInput.name}
                              placeholder={eventVersionInput.placeholder}
                              ref={register(eventVersionInput.register)}
                              required={eventVersionInput.required}
                              type="text"
                            />
                          </Margin>
                        </Grid>
                        <Grid sm={4}>
                          <Margin side="bottom" xs={24}>
                            <Input
                              data-testid={organizationIdInput.id}
                              error={!!errors[organizationIdInput.name]}
                              errorMsg={errors[organizationIdInput.name]?.message}
                              id={organizationIdInput.id}
                              label={organizationIdInput.label}
                              name={organizationIdInput.name}
                              placeholder={organizationIdInput.placeholder}
                              ref={register(organizationIdInput.register)}
                              required={organizationIdInput.required}
                              type="text"
                            />
                          </Margin>
                        </Grid>
                      </Grid>
                    </Margin>
                    <div className={styles.dividerSlim} />
                  </Margin>
                  <Margin side="bottom" xs={24}>
                    <Margin side="bottom" xs={24}>
                      <Typography data-testid="textDescriptionInstanceSize">
                        Select the size for the verification instance{' '}
                      </Typography>
                    </Margin>
                    <Margin side="bottom" xs={24}>
                      <Grid row>
                        <Grid sm={3}>
                          <Margin side="bottom" xs={24}>
                            <Controller
                              control={control}
                              name={instanceSizeDropdown.name}
                              rules={{ required: instanceSizeDropdown.required }}
                              render={({ onChange, onBlur, value, name }) => (
                                <DropdownController
                                  error={!!errors[name]}
                                  errorMsg={errors[name]?.message}
                                  id={instanceSizeDropdown.id}
                                  label={instanceSizeDropdown.label}
                                  name={name}
                                  onBlur={onBlur}
                                  onChange={e => {
                                    onChange(e.value);
                                  }}
                                  options={instanceSizeOptions}
                                  placeholder={
                                    instanceSizeOptions.find(option => option.value === value)?.label ||
                                    instanceSizeDropdown.placeholder
                                  }
                                  required={instanceSizeDropdown.required}
                                  className={styles.systemDropdown}
                                />
                              )}
                            />
                          </Margin>
                        </Grid>
                      </Grid>
                    </Margin>
                    <div className={styles.dividerSlim} />
                  </Margin>
                  <Margin side="bottom" xs={24}>
                    <Typography data-testid="textDescriptionOfFilter">
                      Add the properties that you want to verify in messages
                    </Typography>
                  </Margin>
                  <Margin side="bottom" xs={24}>
                    <Grid row>
                      <Grid sm={12}>
                        <ManageValuesToVerify
                          checkIfPropertyIsValid={checkIfPropertyIsValid}
                          checkIfValueIsValid={checkIfValueIsValid}
                          setProperty={setPropertyToShare}
                          property={propertyToShare}
                          setValue={setValueToShare}
                          value={valueToShare}
                          values={propertiesToShare}
                          addValue={addProperty}
                          removeValue={removeProperty}
                        />
                      </Grid>
                    </Grid>
                  </Margin>
                </Grid>
              </>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            size="sm"
            color="secondary"
            disabled={loading}
            id="btnCloseCreateModal"
            data-testid="btnCloseCreateModal">
            Close
          </Button>

          <Button
            size="sm"
            onClick={handleSubmit(latestValues => {
              let startDate = moment(latestValues[startDateInput.name]);
              let endDate = moment(latestValues[endDateInput.name]);
              if (!startDate.isSameOrBefore(endDate)) {
                setErrorInForm('The end date, must be after than the start date');
                setTimeout(() => setErrorInForm(''), 5000);
              } else if (
                propertiesToShare.length === 0 &&
                !latestValues?.organizationId &&
                !latestValues?.eventVersion &&
                !latestValues?.eventType
              ) {
                setErrorInForm('Swould select a properties or headers to verify in messages');
                setTimeout(() => setErrorInForm(''), 5000);
              } else {
                onSubmit(latestValues, propertiesToShare);
              }
            })}
            disabled={!formState.isValid || loading}
            loading={loading}
            id="btnAcceptCreateModal"
            data-testid="btnAcceptCreateModal">
            {loading ? 'Creating' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
      <Notification
        id="notificationFormValidError"
        data-testid="notificationFormValidError"
        open={Boolean(errorInForm)}
        color="warning"
        colorInherit>
        <Typography>{errorInForm}</Typography>
      </Notification>
    </>
  );
};

CreateVerificationMessageModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  topicsState: PropTypes.object,
  getTopics: PropTypes.func,
  resetGetTopics: PropTypes.func,
  systemsList: PropTypes.array,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default CreateVerificationMessageModal;

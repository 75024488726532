import { urlRegex, versionRegex, UUIDRegex, onlyAlphanumericRegex } from 'utils/validations';
import { METHODS } from 'utils/constants';

export const systemDropdown = {
  id: 'dropdownSystem',
  name: 'system',
  label: 'System',
  required: true,
  type: 'select',
  placeholder: 'Select system',
  register: {
    required: 'System is required',
  },
};

export const instanceSizeDropdown = {
  id: 'dropdownInstanceSize',
  name: 'instanceSize',
  label: 'Instance Size',
  required: true,
  type: 'select',
  placeholder: 'Select instance size',
  register: {
    required: 'Instance size is required',
  },
};

export const topicDropdown = {
  id: 'topicDropdown',
  name: 'topic',
  label: 'Topic',
  required: true,
  type: 'select',
  placeholder: 'Select topic',
};

export const consumerInput = {
  id: 'consumerInput',
  name: 'consumer',
  label: 'Consumer',
  placeholder: 'Select consumer',
  required: true,
  type: 'select',
  register: {
    required: 'Consumer is required',
  },
};

export const webhookUrlInput = {
  id: 'webhookUrlInput',
  name: 'webhookUrl',
  label: 'Webhook URL',
  placeholder: 'Enter webhook url',
  required: true,
  register: {
    required: 'Webhook url is required',
    pattern: {
      value: urlRegex,
      message: 'URL format is incorrect, please verify',
    },
    minLength: {
      value: 5,
      message: 'URL length must be 5 letters at least',
    },
  },
};

export const methodsDropdown = {
  id: 'dropdownMethods',
  name: 'webhookMethod',
  label: 'Method',
  required: true,
  type: 'select',
  options: METHODS,
  placeholder: 'HTTP method',
};

export const validateHeaders = value => {
  if (value.length > 0) {
    try {
      return !!JSON.parse(value);
    } catch (e) {
      return value ? 'Should be a valid JSON format' : true;
    }
  } else {
    return 'Headers are required';
  }
};

export const validateSecret = value => {
  if (value?.length >= 5) {
    return !!value;
  } else {
    return 'Should be a value of minumum 5 leters';
  }
};

export const validateClientId = value => {
  if (value?.length >= 5) {
    return !!value;
  } else {
    return 'Should be a value of minumum 5 leters';
  }
};

export const headersInput = {
  id: 'inputHeaders',
  name: 'webhookHeaders',
  label: 'Headers',
  placeholder: 'Set required headers',
  required: true,
  register: {
    required: 'Headers are required',
    validate: validateHeaders,
  },
};

export const startPausedCheckbox = {
  id: 'checkboxStartPaused',
  name: 'paused',
  label: 'Start paused',
};

export const clientInput = {
  id: 'inputClient',
  name: 'clientId',
  label: 'Client Id',
  placeholder: 'Enter a client Id',
  required: true,
  register: {
    required: 'The client is required',
    validate: validateClientId,
  },
};

export const secretKeyInput = {
  id: 'inputSecret',
  name: 'secret',
  label: 'Client secret',
  placeholder: 'Enter a client secret',
  required: true,
  register: {
    required: 'The client secret is required',
    validate: validateSecret,
  },
};

export const authorizerCheckbox = {
  id: 'checkboxAuthorizer',
  name: 'authorizer',
  label: 'Use Authorizer',
};

export const eventTypeInput = {
  id: 'inputEventType',
  name: 'eventType',
  label: 'Event Type',
  placeholder: 'Enter an event type name',
  required: false,
  register: {
    // required: 'The event type is required',
    pattern: {
      value: onlyAlphanumericRegex,
      message: 'Special chars are not allowed.',
    },
  },
};

export const organizationIdInput = {
  id: 'inputOrganizationId',
  name: 'organizationId',
  label: 'Organization Id',
  placeholder: 'Enter a organization id',
  required: false,
  register: {
    pattern: {
      value: UUIDRegex,
      message: 'UUID format is incorrect, please verify',
    },
  },
};

export const eventVersionInput = {
  id: 'inputEventVersion',
  name: 'eventVersion',
  label: 'Event Version',
  placeholder: 'Enter an event version',
  required: false,
  register: {
    // required: 'The event version is required',
    pattern: {
      value: versionRegex,
      message: 'Version format is incorrect, please verify',
    },
  },
};

export const authorizerUrlInput = {
  id: 'inputAuthorizerUrl',
  name: 'issuerUrl',
  label: 'Issuer URL',
  placeholder: 'Enter issuer URL',
  required: true,
  register: {
    required: 'Issuer URL is required',
    pattern: {
      value: urlRegex,
      message: 'URL format is incorrect, please verify',
    },
    minLength: {
      value: 5,
      message: 'URL length must be 5 letters at least',
    },
  },
};

export const startDateInput = {
  id: 'startDateInput',
  name: 'startDate',
  label: 'Start Date',
  required: true,
  register: {
    required: 'Date is required',
  },
};

export const endDateInput = {
  id: 'endDateInput',
  name: 'endDate',
  label: 'End Date',
  required: true,
  register: {
    required: 'Date is required',
  },
};

export const sortRecordsByTopic = (recordsList = []) => recordsList.sort((a, b) => a.topic.localeCompare(b.topic));

import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import { GET_SYSTEMS, GET_CONSUMERS, CREATE_SYSTEM, GET_ALL_SYSTEMS, EDIT_SYSTEM, DELETE_SYSTEM } from './constants';

export const getSystems = extendRoutine(createRoutine(GET_SYSTEMS), 'RESET');
export const getAllSystems = extendRoutine(createRoutine(GET_ALL_SYSTEMS), 'RESET');
export const getConsumers = extendRoutine(createRoutine(GET_CONSUMERS), 'RESET');
export const createSystem = extendRoutine(createRoutine(CREATE_SYSTEM), 'RESET');
export const editSystem = extendRoutine(createRoutine(EDIT_SYSTEM), 'RESET');
export const deleteSystem = extendRoutine(createRoutine(DELETE_SYSTEM), 'RESET');

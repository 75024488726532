import { envConfig } from 'config';
import request from 'utils/request';

const API_BASE_URL = `${envConfig.API_BASE}/api`;

export function getTopicsRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/topics`, {
    method: 'GET',
    ...options,
  });
}

/* eslint-disable react/display-name */
import React from 'react';

import getDisplayName from 'utils/getDisplayName';

import useFeature from './hooks/useFeature';

export default function withFeature(featureName) {
  return Component => props => {
    const hasFeature = useFeature(featureName);
    if (!hasFeature) return null;

    Component.displayName = `withFeature(${getDisplayName(Component)})`;
    return <Component {...props} />;
  };
}

export const sortSystemsByName = (systemsList = []) => systemsList.sort((a, b) => a.name.localeCompare(b.name));

export const systemsOptions = systemsList => {
  return systemsList.map(item => {
    return {
      value: item.name,
      label: item.friendlyName,
    };
  });
};

export const sortConsumersByName = (consumersList = []) => consumersList.sort((a, b) => a.name.localeCompare(b.name));

export const consumersOptions = consumersList => {
  return consumersList.map(item => {
    return {
      value: item.name,
      label: item.friendlyName,
    };
  });
};

export const systemNameInput = {
  id: 'systemNameInput',
  name: 'name',
  label: 'Name',
  placeholder: 'Name',
  register: {
    required: 'System is required',
  },
};

export const friendlyNameInput = {
  id: 'friendlyNameInput',
  name: 'friendlyName',
  label: 'Friendly Name',
  placeholder: 'Friendly Name',
  register: {
    required: 'System is required',
  },
};

export const isConsumerCheckbox = {
  id: 'isConsumerCheckbox',
  name: 'isConsumer',
  label: 'This system can be consumer',
};

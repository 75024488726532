import produce from 'immer';

import { getTopics } from './actions';

export const initialState = {
  topics: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getTopics.REQUEST:
        draft.topics.loading = true;
        draft.topics.loaded = false;
        draft.topics.error = null;
        break;

      case getTopics.SUCCESS:
        draft.topics.loading = false;
        draft.topics.loaded = true;
        draft.topics.error = null;
        draft.topics.list = payload;
        break;

      case getTopics.FAILURE:
        draft.topics.loading = false;
        draft.topics.loaded = false;
        draft.topics.error = payload;
        break;

      case getTopics.RESET:
        draft.topics = { ...initialState.topics };
        break;
    }
  });

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';

import { DisplayMessage, Button, Margin } from '@gbm/queen-ui-guidelines';

import styles from './styles.module.scss';

function PageError({ id, message, labelAction, onAction, variant, ...others }) {
  return (
    <div id={id} data-testid={id} className={styles.pageError} {...others}>
      <Margin side="bottom" xs={24}>
        <DisplayMessage message={message} variant={variant} />
      </Margin>

      <Button data-testid={`btn-${id}`} onClick={onAction}>
        {labelAction}
      </Button>
    </div>
  );
}

PageError.propTypes = {
  id: PropTypes.string,
  labelAction: PropTypes.string,
  message: PropTypes.string,
  onAction: PropTypes.func,
  variant: PropTypes.string,
};

PageError.defaultProps = {
  variant: 'error',
};

export default PageError;

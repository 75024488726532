import { useContext } from 'react';

import { SystemsContext } from './index';

export function useSelectorsSystems() {
  const { state } = useContext(SystemsContext);

  return {
    systemsState: state.systems,
    consumersState: state.consumers,
    createSystemState: state.createSystem,
    allSystemsState: state.systemsAll,
    editSystemState: state.editSystem,
    deleteSystemState: state.deleteSystem,
  };
}

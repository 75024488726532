import produce from 'immer';

import { 
  getSystems, 
  getConsumers, 
  getAllSystems, 
  createSystem, 
  editSystem, 
  deleteSystem,
} from './actions';

export const initialState = {
  systems: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
  consumers: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
  systemsAll: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
  createSystem: {
    loading: false,
    loaded: false,
    error: null,
  },
  editSystem: {
    loading: false,
    loaded: false,
    error: null,
  },
  deleteSystem: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getSystems.REQUEST:
        draft.systems.loading = true;
        draft.systems.loaded = false;
        draft.systems.error = null;
        break;

      case getSystems.SUCCESS:
        draft.systems.loading = false;
        draft.systems.loaded = true;
        draft.systems.error = null;
        draft.systems.list = payload;
        break;

      case getSystems.FAILURE:
        draft.systems.loading = false;
        draft.systems.loaded = false;
        draft.systems.error = payload;
        break;

      case getSystems.RESET:
        draft.systems = { ...initialState.systems };
        break;

      case getConsumers.REQUEST:
        draft.consumers.loading = true;
        draft.consumers.loaded = false;
        draft.consumers.error = null;
        break;

      case getConsumers.SUCCESS:
        draft.consumers.loading = false;
        draft.consumers.loaded = true;
        draft.consumers.error = null;
        draft.consumers.list = payload;
        break;

      case getConsumers.FAILURE:
        draft.consumers.loading = false;
        draft.consumers.loaded = false;
        draft.consumers.error = payload;
        break;

      case getConsumers.RESET:
        draft.consumers = { ...initialState.consumers };
        break;

      case getAllSystems.REQUEST:
        draft.systemsAll.loading = true;
        draft.systemsAll.loaded = false;
        draft.systemsAll.error = null;
        break;

      case getAllSystems.SUCCESS:
        draft.systemsAll.loading = false;
        draft.systemsAll.loaded = true;
        draft.systemsAll.error = null;
        draft.systemsAll.list = payload;
        break;

      case getAllSystems.FAILURE:
        draft.systemsAll.loading = false;
        draft.systemsAll.loaded = false;
        draft.systemsAll.error = payload;
        break;

      case getAllSystems.RESET:
        draft.systemsAll = { ...initialState.systemsAll };
        break;
     
      case createSystem.REQUEST:
        draft.createSystem.loading = true;
        draft.createSystem.loaded = false;
        draft.createSystem.error = null;
        break;

      case createSystem.SUCCESS:
        draft.createSystem.loading = false;
        draft.createSystem.loaded = true;
        draft.createSystem.error = null;
        draft.createSystem.list = payload;
        break;

      case createSystem.FAILURE:
        draft.createSystem.loading = false;
        draft.createSystem.loaded = false;
        draft.createSystem.error = payload;
        break;

      case createSystem.RESET:
        draft.createSystem = { ...initialState.createSystem };
        break;

      case editSystem.REQUEST:
        draft.editSystem.loading = true;
        draft.editSystem.loaded = false;
        draft.editSystem.error = null;
        break;

      case editSystem.SUCCESS:
        draft.editSystem.loading = false;
        draft.editSystem.loaded = true;
        draft.editSystem.error = null;
        draft.editSystem.list = payload;
        break;

      case editSystem.FAILURE:
        draft.editSystem.loading = false;
        draft.editSystem.loaded = false;
        draft.editSystem.error = payload;
        break;

      case editSystem.RESET:
        draft.editSystem = { ...initialState.editSystem };
        break;

      case deleteSystem.REQUEST:
        draft.deleteSystem.loading = true;
        draft.deleteSystem.loaded = false;
        draft.deleteSystem.error = null;
        break;

      case deleteSystem.SUCCESS:
        draft.deleteSystem.loading = false;
        draft.deleteSystem.loaded = true;
        draft.deleteSystem.error = null;
        draft.deleteSystem.list = payload;
        break;

      case deleteSystem.FAILURE:
        draft.deleteSystem.loading = false;
        draft.deleteSystem.loaded = false;
        draft.deleteSystem.error = payload;
        break;

      case deleteSystem.RESET:
        draft.deleteSystem = { ...initialState.deleteSystem };
        break;
    }
  });

export default reducer;

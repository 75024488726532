import { envConfig } from 'config';
import request from 'utils/request';

const API_BASE_URL = `${envConfig.API_BASE}/api`;

export function getSystemsRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/systems/producers`, {
    method: 'GET',
    ...options,
  });
}

export function getConsumersRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/systems/consumers`, {
    method: 'GET',
    ...options,
  });
}

export function getAllSystemsRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/systems`, {
    method: 'GET',
    ...options,
  });
}

export function createSystemRequest( {name, friendlyName, isConsumer }, options = {}) {
  return request(`${API_BASE_URL}/v1/systems`, {
    method: 'POST',
    body: {
      name: name,
      friendly_name: friendlyName,
      is_consumer: isConsumer,
    },
    ...options,
  });
}

export function editSystemRequest( {id, friendlyName, isConsumer }, options = {}) {
  return request(`${API_BASE_URL}/v1/systems/${id}`, {
    method: 'PATCH',
    body: {
      friendly_name: friendlyName,
      is_consumer: isConsumer,
    },
    ...options,
  });
}

export function deleteSystemRequest( {id }, options = {}) {
  return request(`${API_BASE_URL}/v1/systems/${id}`, {
    method: 'DELETE',
    ...options,
  });
}
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { NavLink } from 'react-router-dom';

import './styles.scss';

class MenuGroup extends React.PureComponent {
  static propTypes = {
    content: PropTypes.array.isRequired,
    small: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { content, small } = this.props;

    let items = [];
    content.forEach((elem, i) => {
      let icon = elem.icon;
      let menuGroup = null;
      let toggle = null;
      let groupActiveClass = null;

      if (elem.to !== undefined) {
        elem.hasPermission &&
          items.push(
            <li key={i + groupActiveClass} className={groupActiveClass}>
              <NavLink exact to={elem.to} activeClassName="active">
                {toggle}
                {icon} <span className={clsx('sidebarLabel', { sidebarLabelSmall: small })}>{elem.label}</span>
              </NavLink>
              {menuGroup}
            </li>
          );
      }
    });
    return <ul className="nav">{items}</ul>;
  }
}

class SideNav extends React.Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    small: PropTypes.bool,
  };

  render() {
    const { content, small } = this.props;

    const item = [
      <nav key="nav" className={clsx('sidebar', { sidebarSmall: small })} role="navigation">
        <MenuGroup content={content} small={small} />
      </nav>,
    ];

    return item;
  }
}

export default SideNav;

import produce from 'immer';

import {
  getVerificationMessagesHistory,
  createVerificationMessages,
  deleteVerificationMessages,
  getDetailVerificationMessages,
} from './actions';

export const initialState = {
  verifyMessages: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
  detailVerificationMessages: {
    loading: false,
    loaded: false,
    error: null,
    verificationDetail: null,
  },
  createVerificationMessages: {
    loading: false,
    loaded: false,
    error: null,
  },
  deleteVerificationMessages: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getVerificationMessagesHistory.REQUEST:
        draft.verifyMessages.loading = true;
        draft.verifyMessages.loaded = false;
        draft.verifyMessages.error = null;
        break;

      case getVerificationMessagesHistory.SUCCESS:
        draft.verifyMessages.loading = false;
        draft.verifyMessages.loaded = true;
        draft.verifyMessages.error = null;
        draft.verifyMessages.list = payload;
        break;

      case getVerificationMessagesHistory.FAILURE:
        draft.verifyMessages.loading = false;
        draft.verifyMessages.loaded = false;
        draft.verifyMessages.error = payload;
        break;

      case getVerificationMessagesHistory.RESET:
        draft.verifyMessages = { ...initialState.verifyMessages };
        break;
      case getDetailVerificationMessages.REQUEST:
        draft.detailVerificationMessages.loading = true;
        draft.detailVerificationMessages.loaded = false;
        draft.detailVerificationMessages.error = null;
        break;

      case getDetailVerificationMessages.SUCCESS:
        draft.detailVerificationMessages.loading = false;
        draft.detailVerificationMessages.loaded = true;
        draft.detailVerificationMessages.error = null;
        draft.detailVerificationMessages.verificationDetail = payload;
        break;

      case getDetailVerificationMessages.FAILURE:
        draft.detailVerificationMessages.loading = false;
        draft.detailVerificationMessages.loaded = false;
        draft.detailVerificationMessages.error = payload;
        break;

      case getDetailVerificationMessages.RESET:
        draft.detailVerificationMessages = { ...initialState.detailVerificationMessages };
        break;
      case createVerificationMessages.REQUEST:
        draft.createVerificationMessages.loading = true;
        draft.createVerificationMessages.loaded = false;
        draft.createVerificationMessages.error = null;
        break;

      case createVerificationMessages.SUCCESS:
        draft.createVerificationMessages.loading = false;
        draft.createVerificationMessages.loaded = true;
        draft.createVerificationMessages.error = null;
        break;

      case createVerificationMessages.FAILURE:
        draft.createVerificationMessages.loading = false;
        draft.createVerificationMessages.loaded = false;
        draft.createVerificationMessages.error = payload;
        break;

      case createVerificationMessages.RESET:
        draft.createVerificationMessages = { ...initialState.createVerificationMessages };
        break;

      case deleteVerificationMessages.REQUEST:
        draft.deleteVerificationMessages.loading = true;
        draft.deleteVerificationMessages.loaded = false;
        draft.deleteVerificationMessages.error = null;
        break;

      case deleteVerificationMessages.SUCCESS:
        draft.deleteVerificationMessages.loading = false;
        draft.deleteVerificationMessages.loaded = true;
        draft.deleteVerificationMessages.error = null;
        break;

      case deleteVerificationMessages.FAILURE:
        draft.deleteVerificationMessages.loading = false;
        draft.deleteVerificationMessages.loaded = false;
        draft.deleteVerificationMessages.error = payload;
        break;

      case deleteVerificationMessages.RESET:
        draft.deleteVerificationMessages = { ...initialState.deleteVerificationMessages };
        break;
    }
  });

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';

import {
  DisplayMessage,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Button,
  Icon,
} from '@gbm/queen-ui-guidelines';

import PlaceholdersTable from 'components/PlaceholdersTable';
import PageError from 'components/PageError';
import styles from './styles.module.scss';
import IconDelete from 'components/IconDelete';
import { useFeature } from 'providers/FeatureProvider';
import { getFormatDateTime } from 'utils/dates';

function TableHistoryOfVerifications({
  error,
  loaded,
  loading,
  verificationsList,
  onReload,
  handleDetailVerification,
  handleDeleteVerification,
}) {
  const canDeleteVerificationsMessages = useFeature('verificationMessagesDelete');
  const canReadVerificationsMessages = useFeature('verificationMessagesRead');
  const showActions = canDeleteVerificationsMessages || canReadVerificationsMessages;

  if (error) {
    return (
      <PageError
        id="msgVerificationPageError"
        message="¡Oops! The information could not be loaded"
        labelAction="Retry"
        onAction={onReload}
      />
    );
  }

  if (loaded && !verificationsList.length) {
    return (
      <DisplayMessage
        id="msgVerificationEmptyInfo"
        data-testid="msgVerificationEmptyInfo"
        variant="info"
        message="Currently, there are no verifications registered"
      />
    );
  }

  return (
    <Table>
      <TableHead variant="dark">
        <TableRow>
          <TableColumn>Status</TableColumn>
          <TableColumn>Messages found</TableColumn>
          <TableColumn>Topic</TableColumn>
          <TableColumn>Start Date</TableColumn>
          <TableColumn>End Date</TableColumn>
          {showActions && <TableColumn className={styles.tableCellWrapper}>Actions</TableColumn>}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <PlaceholdersTable placeholderCols={showActions ? 6 : 5} />
        ) : (
          verificationsList.map((item, key) => (
            <TableRow key={item.id} id={`verificationRow${key}`}>
              <TableCell>
                <Typography monospace>
                  {item.verificationStatus === 'PENDING' ? 'RUNNING' : item.verificationStatus}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography monospace>{item.messagesCounter}</Typography>
              </TableCell>
              <TableCell>
                <Typography monospace>{item.topic}</Typography>
              </TableCell>
              <TableCell>
                <Typography monospace>{getFormatDateTime(item.startDate)}</Typography>
              </TableCell>
              <TableCell>
                <Typography monospace>{getFormatDateTime(item.endDate)}</Typography>
              </TableCell>
              {showActions && (
                <TableCell className={styles.tableCellWrapper}>
                  {canReadVerificationsMessages && (
                    <Tooltip message="Detail" position="top">
                      <Button
                        icon
                        size="xs"
                        color="light"
                        id={`btnDetailVerification${key}`}
                        data-testid={`btnDetailVerification${key}`}
                        onClick={() => handleDetailVerification(item)}>
                        <Icon className="remixicon-eye-fill" />
                      </Button>
                    </Tooltip>
                  )}

                  {canDeleteVerificationsMessages && (
                    <Tooltip message="Delete" position="top">
                      <Button
                        icon
                        size="xs"
                        color="light"
                        id={`btnDeleteVerification${key}`}
                        data-testid={`btnDeleteVerification${key}`}
                        disabled={item.verificationStatus === 'PENDING'}
                        onClick={() => handleDeleteVerification(item)}>
                        <IconDelete />
                      </Button>
                    </Tooltip>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}

TableHistoryOfVerifications.propTypes = {
  error: PropTypes.bool,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  verificationsList: PropTypes.array,
  onReload: PropTypes.func,
  handleDetailVerification: PropTypes.func,
  handleDeleteVerification: PropTypes.func,
};

export default TableHistoryOfVerifications;
